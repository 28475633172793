import React from 'react'
import { images } from 'config/images'
import { EXTERNAL_LINK } from 'config/utils'

export const UnifiedOperationsProps = {
  bannerProps: {
    bannerHeading: (
      <>
        Unify data silos, accelerate analytics, and enable{' '}
        <span className="heading-red">
          real-time decision-making with our expertise
        </span>
      </>
    ),
    bannerContent:
      'We implement automated data integration and efficient ETL workflows to ensure your data is accessible across all data stores.',
    BannerAlt: `Unified operations with Simform's automated workflows`,
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        Unified operations with{' '}
        <span className="highlight-head">Simform's</span> automated workflows
      </>
    ),
    headDetails: [
      {
        Para: (
          <>
            Many organizations face difficulties integrating different data
            sources, leading to data silos and operational issues. This makes it
            harder to manage changing workloads and large datasets, affecting
            operational efficiency and decision-making.
            <br /> <br />
            Simform addresses these challenges by implementing automated data
            workflows. We adeptly integrate various data sources and optimize
            storage and retrieval processes to ensure smooth operations and
            improved decision-making capabilities.
          </>
        ),
      },
      {
        detailsList: [
          'Advanced data processing techniques',
          'Robust data architecture design',
          'Efficient ETL (Extract, Transform, Load) processes',
          'Data modeling and optimization strategies',
          'Expertise in data migration and integration',
          'Streamlined data quality management',
          'Specialized data governance and compliance solutions',
          'Scalable and secure data storage solutions',
        ],
      },
    ],
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogosrc: images.FujiFilmGray,
        clientLogosrcg: images.fujiFilmLogo,
        clientLogoAlt: 'Fuji Film',
      },
      {
        clientLogosrc: images.renesaslogoGray,
        clientLogosrcg: images.renesasLogo,
        clientLogoAlt: 'Renesas',
      },
      {
        clientLogosrc: images.Hyundai_Glovis_logoGray,
        clientLogosrcg: images.hyundaiGlovisLogo,
        clientLogoAlt: 'Hyundai Glovis',
      },
      {
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogosrc: images.mitsubishilogoGray,
        clientLogosrcg: images.mitsubishiLogo,
        clientLogoAlt: 'Mitsubishi Electric',
      },
      {
        clientLogosrc: images.TrygGray,
        clientLogosrcg: images.trygCLogo,
        clientLogoAlt: 'Tryg',
      },
      {
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogosrcg: images.fihCLogo,
        clientLogosrc: images.FIHGray,
        clientLogoAlt: 'Fih',
      },
      {
        clientLogosrc: images.HiltonGray,
        clientLogosrcg: images.hiltonLogo,
        clientLogoAlt: 'Hilton',
      },
      {
        clientLogosrc: images.kirloskarlogoGray,
        clientLogosrcg: images.kirloskarBrothersLogo,
        clientLogoAlt: 'kirloskar Brothers',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  serviceScrollSecProps: {
    servicesHeading: (
      <>
        Our AWS Data Engineering{' '}
        <span className="highlight-head">Services</span>
      </>
    ),
    servicesPara:
      'Simform go beyond technicalities to build a strong data ecosystem. Our expertise covers data collection, organization, analysis, and actionable insights, ensuring maximum organizational value.  With advanced proficiency in platforms like Snowflake, we optimize data efficiency by simplifying database operations, ETL, and BI complexities. This allows you to focus on achieving seamless business outcomes.',
    sidebarHeading: 'Services',
    serviceListProps: [
      {
        heading: 'AWS Glue',
        className: 'custom-software',
        mainPara: (
          <>
            We help you streamline data integration with AWS Glue, ensuring
            seamless data discovery, preparation, and transformation. Our
            holistic approach, with automated workflows, enhances data movement
            and transformation, eliminating data silos and operational
            inefficiencies.
          </>
        ),
        listItem: [
          {
            title: 'Streamline data organization',
            para:
              'We automate data cataloging, identify source schema, infer metadata, and create a centralized catalog for enhanced visibility across resources.',
          },
          {
            title: 'Optimize resource utilization',
            para:
              'Our specialists streamline resource management for efficient workload execution. This construct ETL infrastructure to enhance cost-effectiveness operations.',
          },
          {
            title: 'Streamlined schema conversions',
            para:
              'We convert semi-structured and relational schemas to boost data processing and storage efficiency for improved system performance.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/aws-glue/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'Amazon Opensearch',
        className: 'software-testing',
        mainPara: `Our experts architect, deploy, and optimize Amazon OpenSearch into your systems by tailoring high-performance clusters to your needs. This transforms your data into insights that improve the user experience and operational efficiency. `,
        listItem: [
          {
            title: 'Interactive data insights',
            para:
              'We create user-friendly solutions with OpenSearch Dashboards for informed decision-making through well-designed and interactive visualizations.',
          },
          {
            title: 'Efficient data handling',
            para:
              'Our specialists devise optimal strategies for ingesting data into Amazon OpenSearch from diverse sources and establish effective data analytics pipelines.',
          },
          {
            title: 'Seamless migration support',
            para:
              'We make it easy to switch from older OpenSearch or self-managed Elasticsearch setups to the latest version to minimize downtime.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/amazon-opensearch/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'Amazon RDS',
        className: 'enterprise-mobile',
        mainPara:
          'We automate backups, manage patches, and issue early failure warnings for streamlined operations. Prioritizing robust security, we employ IAM, automated backups, network isolation, and encryption to safeguard sensitive data. This ensures compliance and user trust.',
        listItem: [
          {
            title: 'Simplified RDS management',
            para:
              'Delegate software patching, backups, and recovery tasks to us. We let you focus more on core product development efforts and resource allocation.',
          },
          {
            title: 'Effective scaling plans',
            para:
              'Our AWS experts manage workload growth and surges by adjusting instance sizes or adding read replicas as needed.',
          },
          {
            title: 'Optimized performance enhancement',
            para:
              'We analyze database performance, fine-tuning parameters for enhanced throughput and responsiveness under heavy workloads.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/amazon-rds/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'Amazon DynamoDB',
        className: 'web-application',
        mainPara:
          'Simform helps you deploy, manage, and optimize your distributed database with Amazon DynamoDB expertise. Our AWS experts ensure single-digit millisecond performance and stringent security in your applications through a fully managed NoSQL infrastructure.',
        listItem: [
          {
            title: 'Custom data models',
            para:
              'Our experts design application-specific data models, optimizing data organization in DynamoDB for efficient management and scalable cost-effectiveness.',
          },
          {
            title: 'Swift data processing',
            para:
              'Simform integrates DynamoDB Streams with AWS Lambda for real-time data processing, offering adaptability and instant insights.',
          },
          {
            title: 'Streamlined data ingestion',
            para:
              'Efficiently load high volumes into DynamoDB with batch loading, parallel processing, and optimized formats for seamless business integration.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/amazon-dynamodb/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'Amazon Redshift',
        className: 'dedicated-software',
        mainPara:
          'Our experts assist you in querying and analyzing your structured and semi-structured data across data warehouses and data lakes within seconds. With our tailored approach, we optimize your data warehousing capabilities, fostering a data-driven culture for success and growth.',
        listItem: [
          {
            title: 'Effortless integration',
            para:
              'We seamlessly link with AWS, data lakes, and external sources and smooth integration with BI tools like Tableau and Looker for efficient data utilization.',
          },
          {
            title: 'Advanced analytics',
            para: `Our experts offer real-time insights with features like streaming ingestion, Apache Spark integration, and Data Lake Export to provide valuable business insights.`,
          },
          {
            title: 'Streamlined data management',
            para:
              'We simplify data handling and recovery with efficient workload management, UDFs, and cross-region snapshots for improved performance and reliability.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/amazon-redshift/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'AWS Data Analytics',
        className: 'product-development',
        mainPara:
          'Simform builds customized analytics pipelines to gather, process, and translate complex data into predictive, actionable business intelligence with AWS data analytics expertise.',
        listItem: [
          {
            title: 'Cloud data warehousing',
            para:
              'We optimize data warehouses on Amazon Redshift, centralizing and structuring diverse data for efficient analytics.',
          },
          {
            title: 'Cloud migration and modernization',
            para: `Our experts assess, design, implement, and optimize data workflows for seamless migration to AWS services.`,
          },
          {
            title: 'Data security and governance',
            para:
              'We control access with AWS IAM, encrypt data, and utilize Amazon Macie to discover and classify sensitive data for compliance.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/aws-data-analytics/',
        type: EXTERNAL_LINK,
      },
    ],
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        Why choose <span className="highlight-head"> Simform?</span>
      </>
    ),
    processPara: `As an premier AWS Consulting Partner, we provide services to enhance your business's ability to meet evolving customer demands and remain a reliable market leader worldwide. We ensure a comprehensive set of tools and strategies tailored to all cloud environments.`,
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'AWS certified and recognized',
        workingBenefitsContent: (
          <>
            Our team includes over 200 AWS-certified experts, guaranteeing the
            latest industry knowledge and best practices for your projects.{' '}
            <br />
            <br />
            Trust that your solutions are in the hands of experienced
            professionals equipped to handle complex data engineering challenges
            effectively.
          </>
        ),
        benefitImgAlt: 'AWS certified and recognized',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Scalable architecture',
        workingBenefitsContent: (
          <>
            We assist you in building a complete data pipeline that connects
            your data from strategy to development, deployment, and monitoring.
            <br />
            <br />
            Our experts accelerate the design and development of data-driven
            pipelines, utilizing effective AWS data engineering tools for a
            smooth and efficient integration process.
          </>
        ),
        benefitImgAlt: 'Scalable architecture',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Business-focused analytics',
        workingBenefitsContent: (
          <>
            Simform creates a secure, scalable, and cost-effective data
            analytics framework, simplifying data unification and enrichment.
            <br />
            <br />
            Using AWS services for data engineering, tools, and effective
            approaches, we empower enterprises to effortlessly derive valuable
            data insights.
          </>
        ),
        benefitImgAlt: 'Business-focused analytics',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Faster time-to-value',
        workingBenefitsContent: (
          <>
            With an effective data management system, we leverage our expertise
            to stream, load, and process data volumes.
            <br />
            <br />
            Our highly experienced team of AWS data experts can help you
            increase the visibility of your data sets, minimize potential
            errors, and scale data volumes efficiently.
          </>
        ),
        benefitImgAlt: 'Faster time-to-value',
      },
    ],
  },
  gauranteeSecProps: {
    heading: (
      <>
        Work with an official{' '}
        <span className="highlight-head">AWS Premier Consulting Partner</span>
      </>
    ),
    para:
      'Simform, with its cloud partners, brings you the superpower of cloud-native applications. Our strategic alliances empower organizations like yours to thrive in the cloud.',
    gauranteeAlt: 'Extended tech team',
  },
  awardsRecognitionProps: {
    heading: 'Learn more about our AWS partner programs',
    AwsCardData: [
      {
        cardLink: '/aws-well-architected-review/',
      },
      {
        cardLink: '/aws-immersion-days/',
      },
    ],
  },
  relatedcaselistProps: {
    heading: (
      <>
        <span className="highlight-head">Case Studies</span>
      </>
    ),
    caselistProps: [
      {
        title: 'Automotive Digital Marketing Platform',
        para:
          'Built a high performance, secure and scalable digital marketing platform catering specifically to automotive clients.',
        className: 'light-peach',
        link: '/carsaver-aws-dynamodb/',
      },
      {
        title: 'Innovative Data Solutions for Schools',
        para:
          'Empowering schools with smart data solutions for seamless communication and efficient management',
        className: 'pale-blue',
        link: '/data-design-aws-glue/',
      },
    ],
  },
  rangeServiceListDataProps: {
    RangeServiceListHeading: 'About Simform’s AWS Practices',
    detailsList: [
      <>
        <strong>
          Simform is an official premier consulting partner of AWS
        </strong>{' '}
        and hence, follows an approved set of practices that define the best
        work in configuring AWS services.
      </>,
      <>
        <strong>Devoted subject matter expert</strong> to walk you through your
        project and assist teams during the problems.
      </>,
      <>
        <strong>A well-structured team</strong> of technical officers whose
        significant tenure belongs to cloud technologies and AWS services.
      </>,
      <>
        <strong>A 24/7 constant communication</strong> to help you get through
        the AWS assistance, zero instances of delayed service.
      </>,
      <>
        <strong>Simform’s standard auditing and quality assurance</strong> over
        every module of code we deliver through our consulting services.
      </>,
    ],
  },
  featureResourceProps: {
    heading: 'AWS insights from our experts',
    featureContents: [
      {
        blogLink:
          'https://www.simform.com/blog/best-practices-to-build-data-pipelines/',
        featurecardTitle:
          'Building a Data Pipeline? Don’t Overlook These 7 Factors',
      },
      {
        blogLink:
          'https://www.simform.com/blog/data-warehouse-vs-data-lake-vs-data-lakehouse/',
        featurecardTitle:
          'Data Warehouse vs. Data Lake vs. Data Lakehouse: Which Is Better for Your Business?',
      },
      {
        blogLink: 'https://www.simform.com/blog/data-engineering-tools/',
        featurecardTitle:
          '30+ Top Data Engineering Tools for Each Stage of a Data Pipeline',
      },
    ],
  },
}
